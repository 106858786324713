import axios from 'axios'

const API = 'entregas-dinero'

const entregasDineros = {
    listarEntregas: (params) => axios.get(`${API}/listar-entregas`, { params }),
    listarEntregasFinalizadas: (params) => axios.get(`${API}/listar-entregas-finalizadas`, { params }),
    listarEntregasWebLeechero: () => axios.get(`${API}/listar-entregas-web-leechero`,),
    listarEntregasFinalizadasWebLeechero: (params) => axios.get(`${API}/listar-entregas-finalizadas-web-leechero`, { params }),
    anularWeb: (data) => axios.post(`${API}/anular-web`, data),
    verificarWeb: (data) => axios.post(`${API}/verificar-web`, data ),
    guardar: (data) => axios.post(`${API}/guardar`, data ),
    detalle: (id) => axios.get(`${API}/detalle/${id}`,),
    
}

export default entregasDineros
