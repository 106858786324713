<template>
    <section>
        <div class="row mx-0 align-items-center py-3 bg-white br-t-8">
            <div class="col-auto text-general text-general f-500">
                Entregas de dinero pendientes por verificar
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 ml-auto">
                <el-input v-model="buscar" size="small" placeholder="Buscar en la lista" class="w-100" />
            </div>
        </div>
        <div class="d-middle pl-2 mx-0 pt-3 pb-2">
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                {{ $config.vendedor }}
            </div>
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                Valor
            </div>
            <div class="col text-general f-16 f-600">
                Entrega
            </div>
            <div style="width:50px;height:10px;" />
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                Delivery
            </div>
            <div class="col text-general text-center f-16 f-600">
                Recibido
            </div>
            <div class="col-3 text-general f-16 f-600">
                Verificado
            </div>
        </div>
        <div class="bg-white">
            <div v-for="(data, e) in vigentes" :key="e" class="d-middle border-t-gris br-b-8 pl-2 mx-0 py-2 cr-pointer" :class="finalizadas.length - 1 == e ? 'br-b-8' : ''" @click="detalleEntrega(data)">
                <div class="text-center" style="width:50px;">
                    <img :src="data.tienda_imagen" class="obj-cover rounded-circle border" width="36" height="36" />
                </div>
                <div class="col text-general d-middle f-16 f-600">
                    {{ data.propietario_nombre }}
                </div>
                <div class="" style="width:50px;">
                    <el-tooltip placement="bottom" effect="light">
                        <div slot="content" style="max-width:200px;">
                            {{ data.comentario }}
                        </div>
                        <div v-if="data.comentario" class="bg-white shadow rounded-circle text-general d-middle-center f-20" style="height:39px;width:39px;">
                            <i class="icon-message-text-outline" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col f-14">
                    <p class="text-general f-600">
                        {{ separadorNumero(data.valor) }}
                    </p>
                    <p class="text-general">
                        {{ data.tipo == 1 ? 'Efectivo': (data.tipo == 2 ? 'Transferencia' : 'Pago en línea') }}
                    </p>
                    <p v-if="data.tipo == 3" class="text-general">
                        Ref: {{ _.get(data, 'pasarela_payu.transaction_id', 'No registra') }}
                    </p>
                </div>
                <div class="col text-general f-14">
                    <p>{{ formatearFecha(data.created_at) }}</p>
                    <p> {{ formatearFecha(data.created_at, 'hh:mm a') }} </p>
                </div>
                <div class="text-center" style="width:50px;">
                    <i class="icon-right text-general f-18" />
                </div>
                <div class="text-center" style="width:50px;">
                    <i v-if="data.delivery_nombre == null || [2, 3].includes(data.tipo)" :class="`${[2, 3].includes(data.tipo) ? 'icon-ok-circled-outline' : 'icon-attention-alt'} text-general f-18`" />
                    <img v-else :src="data.delivery_imagen" class="obj-cover rounded-circle border" width="36" height="36" />
                </div>
                <div class="col text-general f-14">
                    {{ data.delivery_nombre != null ? data.delivery_nombre : ([2, 3].includes(data.tipo) ? 'No requiere recibido' : 'Pendiente por recibir') }}
                </div>
                <div class="col text-center text-general f-14">
                    <template v-if="data.fecha_recibio">
                        <p>{{ formatearFecha(data.fecha_recibio) }}</p>
                        <p> {{ formatearFecha(data.fecha_recibio, 'hh:mm a') }} </p>
                    </template>
                    <template v-else>
                        -
                    </template>
                </div>
                <div class="col-3">
                    <div v-if="!(data.tipo == 3 && data.pasarela_payu.state_pol == null)" class="row mx-0">
                        <div v-if="[2, 3].includes(data.tipo) && $can('boton_tesoreria_dinero_recibido_gestionar') && (data.tipo == 3 && data.pasarela_payu.state_pol == 4)" class="bg-general3 text-white br-20 px-2 py-1 mx-1 cr-pointer" @click.stop="anularEntrega(data, 3)">
                            Verificar entrega
                        </div>
                        <div v-if="$can('boton_tesoreria_dinero_recibido_gestionar') && !(data.tipo == 3 && _.get(data, 'pasarela_payu.state_pol', false))" class="bg-light-f5 border br-20 text-general mx-1 d-flex px-2 py-1 cr-pointer" @click.stop="anularEntrega(data,5)">
                            Anular
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Entregas de dinero verificadas -->
        <div class="row mx-0 my-4" />
        <div class="row mx-0 bg-white py-3 br-t-8 align-items-center">
            <div class="col-auto text-general f-500">
                Entregas de dinero verificadas
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 ml-auto">
                <el-input v-model="buscar" size="small" placeholder="Buscar en la lista" class="w-100" />
            </div>
        </div>
        <div class="d-middle pl-2 mx-0 pt-3 pb-2">
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                {{ $config.vendedor }}
            </div>
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                Valor
            </div>
            <div class="col text-general f-16 f-600">
                Entrega
            </div>
            <div style="width:50px;height:10px;" />
            <div style="width:50px;height:10px;" />
            <div class="col text-general f-16 f-600">
                Delivery
            </div>
            <div class="col text-general text-center f-16 f-600">
                Recibido
            </div>
            <div class="col-3 text-general f-16 f-600">
                Verificado
            </div>
        </div>
        <div class="bg-white">
            <div v-for="(data, k) in finalizadas" :key="`d-${k}`" class="d-middle pl-2 mx-0 border-t-gris py-2 cr-pointer" :class="finalizadas.length - 1 == k ? 'br-b-8' : ''" @click="detalleEntrega(data)">
                <div class=" text-center" style="width:50px;">
                    <img :src="data.tienda_imagen" class="obj-cover rounded-circle border" width="36" height="36" />
                </div>
                <div class="col text-general d-middle f-16 f-600">
                    {{ data.propietario_nombre }}
                </div>
                <div class="" style="width:50px;">
                    <el-tooltip placement="bottom" effect="light">
                        <div slot="content" style="max-width:200px;">
                            {{ data.comentario }}
                        </div>
                        <div v-if="data.comentario" class="bg-white shadow rounded-circle text-general d-middle-center f-20" style="height:39px;width:39px;">
                            <i class="icon-message-text-outline" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col f-14">
                    <p class="text-general f-600">
                        {{ separadorNumero(data.valor) }}
                    </p>
                    <p class="text-general">
                        {{ data.tipo == 1 ? 'Efectivo': (data.tipo == 2 ? 'Transferencia' : 'Pago en línea') }}
                    </p>
                </div>
                <div class="col text-general f-14">
                    <p>{{ formatearFecha(data.created_at) }}</p>
                    <p> {{ formatearFecha(data.created_at, 'hh:mm a') }} </p>
                </div>
                <div class="text-center" style="width:50px;">
                    <i class="icon-right text-general f-18" />
                </div>
                <div class="text-center" style="width:50px;">
                    <!-- <i class="icon-ok-circled-outline text-general f-18" /> -->
                    <i v-if="data.delivery_nombre == null || [2, 3].includes(data.tipo)" :class="`${[2, 3].includes(data.tipo) ? 'icon-ok-circled-outline' : 'icon-attention-alt'} text-general f-18`" />
                    <img v-else :src="data.delivery_imagen" class="obj-cover rounded-circle border" width="36" height="36" />
                </div>
                <div class="col text-general f-14">
                    {{ data.delivery_nombre != null ? data.delivery_nombre : ([2, 3].includes(data.tipo) ? 'No requiere recibido' : 'Pendiente por recibir') }}
                </div>
                <div class="col text-center text-general f-14">
                    <template v-if="data.fecha_recibio">
                        <p>{{ formatearFecha(data.fecha_recibio) }}</p>
                        <p> {{ formatearFecha(data.fecha_recibio, 'hh:mm a') }} </p>
                    </template>
                    <template v-else>
                        -
                    </template>
                </div>
                <div class="col-3">
                    <div class="row mx-0 align-items-center">
                        <img :src="data.estado == 4 ? data.tienda_imagen : data.admin_imagen " width="36" height="36" class="rounded-circle" />
                        <div class="col text-general f-14">
                            {{ data.estado == 3 ? `Recibido por ${data.admin_nombre}`: (data.estado == 4 ? `Anulado ${data.propietario_nombre}` : `Anulado por ${data.admin_nombre}`) }}
                        </div>
                        <div class="col-auto px-0 text-general f-14">
                            <p>{{ formatearFecha(data.fecha_confirmo) }}</p>
                            <p> {{ formatearFecha(data.fecha_confirmo, 'hh:mm a') }} </p>
                        </div>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                {{ data.anulo_comentario }}
                            </div>
                            <div v-if="data.anulo_comentario" class="bg-white shadow rounded-circle text-general d-middle-center f-20 mx-1" style="height:39px;width:39px;">
                                <i class="icon-message-text-outline" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center text-center mt-3">
            <div class="col-3">
                <div class="row mx-0">
                    <div class="bg-general3 text-white br-20 px-2 py-1 mx-1 cr-pointer" @click="listarFinalizadas(10)">
                        Ver más 10
                    </div>
                    <div class="bg-general3 text-white br-20 px-2 py-1 mx-1 cr-pointer" @click="listarFinalizadas(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-verificado ref="modalVerificado" @saved="init()" />
        <modal-detalle-entrega ref="modalDetalleEntrega" @saved="listarEntregas()" @closed="init()" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import entregasDineros from '~/services/almacen/entregas_dineros'
export default {
    components: {
        modalVerificado: () => import('./partials/modalVerificado.vue'),
        modalDetalleEntrega: () => import('~/pages/almacen/tendero/creditos/partials/modalDetalleEntrega.vue'),
    },
    data(){
        return {
            buscar: '',
            vigentes: [],
            finalizadas: [],
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        id_cedis(){
            this.init()
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        anularEntrega({id}, estado){
            this.$refs.modalVerificado.toggle(id, estado);
        },
        async init(){
            await this.listarEntregas()
            await this.listarFinalizadas()
        },
        detalleEntrega({ id }){
            this.$refs.modalDetalleEntrega.toggle(id);
        },
        async listarEntregas(){
            try {
                const params = {
                    cedi: this.id_cedis,
                }
                const { data } = await entregasDineros.listarEntregas(params)
                this.vigentes = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarFinalizadas(paginate = 10){
            try {
                const { id: lastId = null } = _.last(this.finalizadas) ?? {}
                const params = {
                    paginate,
                    cedi: this.id_cedis,
                    lastId
                }
                const { data } = await entregasDineros.listarEntregasFinalizadas(params)
                this.finalizadas = [...this.finalizadas, ...data.data]
            } catch (error){
                this.error_catch(error)
            }
        },

    }

}
</script>

<style lang="scss" scoped>
</style>
